import {
  DEVELOPER_HEADQUARTERS,
  SUPER_ADMIN,
  DEVELOPER_BRANCH_LEADER_SUPERVISOR,
  DEVELOPER_BRANCH_LEADER_SUPERVISOR_II,
  DEVELOPER_BRANCH_LEADER,
  DEVELOPER_EXECUTIVE,
  DEVELOPER_AGENT_LEADER,
  ADMIN_BASIC,
  ADMIN,
  ADMIN_COMMUNITY,
} from 'src/constants';
import { GroupAccess } from 'src/models/groupAccess';
import analyticsCompanyConfig from 'src/analyticsCompanyConfig.json';

export function hasAccess(userGroup: any) {
  const groups: string[] = [DEVELOPER_HEADQUARTERS, SUPER_ADMIN];
  return Array.isArray(userGroup) && userGroup.length > 0 && groups.some((g) => userGroup.includes(g));
}

export function hasEditProjectAccess(userGroup: any, userBU: any, projectBU: any) {
  let check = false;
  const isBranchLeaderSupervisorII = DEVELOPER_BRANCH_LEADER_SUPERVISOR_II;

  check = hasAccess(userGroup);
  if (Array.isArray(userGroup) && userGroup.includes(isBranchLeaderSupervisorII) && projectBU === userBU) {
    check = true;
  }
  return check;
}

export function hasCreateProjectAccess(userGroup: any) {
  const groups: string[] = [DEVELOPER_HEADQUARTERS, SUPER_ADMIN, DEVELOPER_BRANCH_LEADER_SUPERVISOR_II, DEVELOPER_BRANCH_LEADER];
  return Array.isArray(userGroup) && userGroup.length > 0 && groups.some((g) => userGroup.includes(g));
}

export const checkGroupAccess = (userGroup: any) => {
  const roles: string[] = [
    DEVELOPER_HEADQUARTERS,
    SUPER_ADMIN,
    DEVELOPER_BRANCH_LEADER_SUPERVISOR_II,
    DEVELOPER_BRANCH_LEADER,
    DEVELOPER_BRANCH_LEADER_SUPERVISOR,
  ];
  return Array.isArray(userGroup) && userGroup.length > 0 && roles.some((r) => userGroup.includes(r));
};

export const hasDashboardAccess = (userGroup: any) => {
  const roles: string[] = [
    SUPER_ADMIN,
    ADMIN,
    ADMIN_BASIC,
    ADMIN_COMMUNITY,
  ];
  return Array.isArray(userGroup) && userGroup.length > 0 && roles.some((r) => userGroup.includes(r));
};

export const hasDownloadAccess = (companyID: string) => {
  const companyIDArray = analyticsCompanyConfig.map((company: { company_id: string }) => company.company_id);
  return companyIDArray?.includes(companyID);
};

export const isDeveloper = (groups: any) => groups.some((group: string) => (
  group === DEVELOPER_EXECUTIVE
));

export const isDeveloperAgentLeader = (groups: any) => groups.some((group: string) => (
  group === DEVELOPER_AGENT_LEADER
));

export const isExternalAgent = (groups: any, companyBranch: any) => isDeveloper(groups)
  && !isDeveloperAgentLeader(groups)
  && (companyBranch && companyBranch.isExternal);

export const isBranchLeader = (groups: any) => groups.some((group: string) => (
  group === DEVELOPER_BRANCH_LEADER
));

export const isHeadquarters = (groups: any) => groups.some((group: string) => (
  group === DEVELOPER_HEADQUARTERS
));

export const isSuperAdmin = (groups: any) => groups.some((group: string) => (
  group === SUPER_ADMIN
));

export const hasAdminBasicGroup = (groups: GroupAccess[]) => groups.some((group: GroupAccess) => (
  group.id === ADMIN_BASIC
));
