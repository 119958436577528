export const areaUnits = [
  { text: 'Square feet (sqft)', value: 'square_feet' },
  { text: 'Square metres (sqm)', value: 'square_metres' },
];

export const projectStatuses = [
  { text: 'Draft', value: 'draft' },
  { text: 'Ready', value: 'ready' },
  { text: 'Published', value: 'published' },
  { text: 'Archived', value: 'archived' },
  { text: 'Deleted', value: 'deleted' },
];

export const propertyCategories = [
  { text: 'Single Storey Terrace House', value: 'single_storey_terrace_house' },
  { text: '2 Storey Terrace House', value: '2_storey_terrace_house' },
  { text: '2.5 Storey Terrace House', value: '2_5_storey_terrace_house' },
  { text: '3 Storey Terrace House', value: '3_storey_terrace_house' },
  { text: '3.5 Storey Terrace House', value: '3_5_storey_terrace_house' },
  { text: 'Home/Land', value: 'home_land' },
  { text: 'Apartment', value: 'apartment' },
  { text: 'Flat', value: 'flat' },
  { text: 'Condominium', value: 'condominium' },
  { text: 'Service Residence', value: 'service_residence' },
  { text: 'Terrace', value: 'terrace' },
  { text: 'Superlink Homes', value: 'superlink_homes' },
  { text: 'Townhouse', value: 'townhouse' },
  { text: 'Cluster House', value: 'cluster_house' },
  { text: 'Bungalow', value: 'bungalow' },
  { text: 'SemiD House', value: 'semi_d' },
  { text: 'Villa', value: 'villa' },
  { text: 'Retail Office', value: 'retail_office' },
  { text: 'Retail Space', value: 'retail_space' },
  { text: 'Shop', value: 'shop' },
  { text: 'Shop Office', value: 'shop_office' },
  { text: 'Office', value: 'office' },
  { text: 'Sofo', value: 'sofo' },
  { text: 'Soho', value: 'soho' },
  { text: 'Sovo', value: 'sovo' },
  { text: 'Designer Suites', value: 'designer_suites' },
  { text: 'Business Centre', value: 'business_centre' },
  { text: 'Factory', value: 'factory' },
  { text: 'SemiD Factory', value: 'semi_d_factory' },
  { text: 'Warehouse', value: 'warehouse' },
  { text: 'Detached Factory', value: 'detached_factory' },
  { text: 'Light Industrial', value: 'light_industrial' },
  { text: 'Link Factory', value: 'link_factory' },
  { text: 'Bungalow Land', value: 'bungalow_land' },
];

export const propertyTypes = [
  { text: 'Commercial', value: 'commercial' },
  { text: 'Industrial', value: 'industrial' },
  { text: 'Residential', value: 'residential' },
];

export const tenures = [
  { text: 'Leasehold', value: 'leasehold' },
  { text: 'Freehold', value: 'freehold' },
];

export const salesChartTypes = [
  { text: 'Layout', value: 'layout' },
  { text: 'Position', value: 'position' },
  { text: 'Position layout', value: 'position-layout' },
];

export const salesChartRefreshSeconds = [
  { text: '15', value: 15 },
  { text: '20', value: 20 },
  { text: '30', value: 30 },
  { text: '40', value: 40 },
  { text: '50', value: 50 },
  { text: '60', value: 60 },
  { text: '120', value: 120 },
];

export const overlayModes = [
  { text: 'Text', value: 'text' },
  { text: 'Color', value: 'color' },
];

export const bookingCustomFieldTypes = [
  { value: 'dropdown', text: 'Dropdown single selection' },
  { value: 'dropdown_multi', text: 'Dropdown multiple selection' },
  { value: 'input', text: 'Single line' },
  { value: 'textarea', text: 'Multi line' },
];

export const TAB_ABOUT = { name: 'About', value: 'about' };
export const TAB_SPECIFICATION = { name: 'Specifications', value: 'specifications' };
export const TAB_SALES_CHART_SETUP = { name: 'Sales chart setup', value: 'sales_chart_setup' };
export const TAB_PACKAGE_PRICING = { name: 'Pricing package', value: 'package_pricings' };
export const TAB_GALLERY = { name: 'Gallery', value: 'pictures' };
export const TAB_DOCUMENTS = { name: 'Documents', value: 'documents' };
export const TAB_UNIT_LAYOUTS = { name: 'Unit layouts', value: 'layouts' };
export const TAB_OTHER_SETTINGS = { name: 'Other settings', value: 'other_settings' };
export const TAB_BOOKING_FLOW = { name: 'Booking flow', value: 'booking_flow' };
export const TAB_PAYMENT_SCHEDULE = { name: 'Payment schedule', value: 'payment_schedule' };
export const TAB_AUTO_RELEASE = { name: 'Auto release', value: 'auto_release' };
export const TAB_ACCESS_CONTROL = { name: 'Access control', value: 'access_control' };
export const TAB_EMAIL_NOTIFICATION = { name: 'Email notification', value: 'email_notification' };
export const TAB_PRINTED_FORM = { name: 'Printed form', value: 'printed_form' };
export const TAB_CUSTOM_FIELDS = { name: 'Custom fields', value: 'custom_fields' };
export const TAB_PANEL_BANKERS = { name: 'Panel bankers', value: 'panel_bankers' };
export const TAB_PANEL_LAWYERS = { name: 'Panel lawyers', value: 'panel_lawyers' };
export const TAB_SRB = { name: 'Showroom for Buyer', value: 'srb' };
export const TAB_MP = { name: 'Marketplace', value: 'mp' };
export const TAB_ADDITIONAL_FEES = { name: 'Additional Fees', value: 'additional_fees' };
