/* eslint-disable import/no-cycle */
import { CU_BU_LEADER_ACCESS } from 'src/constants/changeUnits';
import { SiteplanSettings } from 'src/types/siteplanSettings';
import {
  GenericMap,
  ModelID,
} from './root';

import Logo from './logo';
import { BookingStatusID } from './bookingStatus';
import { ProjectBankAccount } from './projectBankAccount';
import { ProjectBookingCustomFieldMutationModel } from './projectBookingCustomField';
import { ProjectFormAccessibility } from './projectFormAccessibility';
import { ProjectPackagePricing } from './projectPackagePricing';
import { ProjectPicture } from './projectPicture';
import { ProjectSiteplan } from './projectSiteplan';
import { ProjectSummary } from './projectSummary';
import { PropertyCategory, PropertyCategoryID } from './propertyCategory';

export type ProjectModel = Project & ModelID;

// The structure of information required to update in the DB
export type ProjectMutationModel = Project & Required<ModelID>;

export enum AreaUnits {
  UNKNOWN = '',
  SQUARE_FEET = 'square_feet',
  SQUARE_METRES = 'square_metres',
}

export enum OwnershipType {
  UNKNOWN = '',
  FREEHOLD = 'freehold',
  LEASEHOLD = 'leasehold',
}

export enum ProjectStatus {
  UNKNOWN = '',
  ARCHIVED = 'archived',
  DELETED = 'deleted',
  DRAFT = 'draft',
  PUBLISHED = 'published',
  READY = 'ready',
}

export enum PriceForBankers {
  SPAPRICE = 'spa_price',
  LISTPRICE = 'list_price',
}

export class Project {
  readonly id?: string = '';

  readonly accessBULNettSellingPrice?: boolean = false;

  readonly accessStaffNettSellingPrice?: boolean = false;

  readonly address1?: string = '';

  readonly address2?: string = '';

  readonly address3?: string = '';

  readonly address4?: string = '';

  readonly allowAgentChangeBookingPaymentMethod?: boolean = true;

  readonly allowAgentEditBookingSPAValue?: boolean = true;

  readonly allowAgentManageBooking?: boolean = true;

  readonly allowAgentManageBookingCustomer?: boolean = true;

  readonly allowAgentChangePrimaryPurchaser?: boolean = false;

  readonly allowAgentReceiveCancelledBookingEmail?: boolean = false;

  readonly allowAgentSalesTeamBankerInvitation?: boolean = true;

  readonly allowCustomBookingStatus?: boolean = true;

  readonly allowStaffEditBookingSPAValue?: boolean = true;

  readonly approvedBookingEmailNotifications?: string[] = [];

  readonly arBookingExpIsEnabled?: boolean = false;

  readonly arBookingExpMoreThanMinutes?: string = '';

  readonly arBookingExpMortgageEnabled?: boolean = false;

  readonly arBookingExpNricPicIsEnabled?: boolean = false;

  readonly arBookingExpAllNricPicsIsEnabled?: boolean = false;

  readonly arBookingExpUnpaidIsEnabled?: boolean = false;

  readonly arBookingAllUnderReserved?: boolean = true;

  readonly arBookingStartDate?: Date = undefined;

  readonly arAgingBookingExpReservedIsEnabled?: boolean = false;

  readonly arAgingBookingExpBookedIsEnabled?: boolean = false;

  readonly arAgingBookingExpMoreThanMinutes?: string = '0';

  readonly autoAssignBankerStatus?: BookingStatusID = BookingStatusID.BOOKED;

  readonly autoAssignLawyerStatus?: string = 'booked';

  readonly autofillFromUrl?: boolean = false;

  readonly bankerSelectionTabs: string[] = ['panel_banker', 'preferred_banker', 'all'];

  readonly bobFormIDs?: string[] = [];

  readonly bookingUnitLimit: number = 1;

  readonly cancelledBookingEmailNotifications?: string[] = [];

  readonly city?: string = '';

  readonly commission?: string = '';

  readonly commissionDescription?: string = '';

  readonly completion?: Date = undefined;

  readonly country?: string = '';

  readonly cuIsEnabled?: boolean = false;

  readonly cuOtherProjectIsEnabled?: boolean = false;

  readonly cuDelistOnHoldIsEnabled?: boolean = false;

  readonly cuRoleAccesses?: string[] = [CU_BU_LEADER_ACCESS];

  readonly customWatermark?: string = '';

  readonly customerAssessmentFlow?: boolean = false;

  readonly customerBookingSuccessEmailType?: string = '';

  readonly defaultBookingFeeAmount?: number = 1;

  readonly defaultBumiProjectPackagePricingID?: string = '';

  readonly defaultBumiProjectPricingID?: string = '';

  readonly defaultCustomBookingStatusID?: string = '';

  readonly defaultProjectPackagePricingID?: string = '';

  readonly defaultProjectPricingID?: string = '';

  readonly email?: string = '';

  readonly externalAutofillUrl?: string = '';

  readonly gps?: string = '';

  readonly hidePrintAgentStaff?: boolean = false;

  readonly hidePublicPriceSalesChart?: boolean = false;

  readonly hidePublicPriceSiteplan?: boolean = false;

  readonly paymsIpMerchantCode?: string = '';

  readonly paymsIpMerchantKey?: string = '';

  readonly priceForBankers?: PriceForBankers = PriceForBankers.SPAPRICE;

  readonly internationalIDRegex?: string = '';

  readonly isAPDL?: boolean = false;

  readonly isCommercial?: boolean = false;

  readonly isIndustrial?: boolean = false;

  readonly isInternational?: boolean = false;

  readonly isLaunchMode?: boolean = false;

  readonly isProjectBAC?: boolean = false;

  readonly isPublic?: boolean = false;

  readonly isResidential?: boolean = false;

  readonly launchModeMinutes?: number = 0;

  readonly linkBuyerApp?: boolean = false;

  readonly loanReminderLetter?: string = '';

  readonly loanTrackerDirectConsent?: boolean = false;

  readonly loanTrackerDirectShow?: boolean = false;

  readonly loanTrackerProjectConsentTypeID?: string = '2';

  readonly loanTrackerProjectID?: number = 0;

  readonly loanTrackerProjectLeadBankersLimit?: number = 3;

  readonly logo?: Logo = {} as Logo;

  readonly logoID?: string = '';

  readonly manualPrice?: boolean = false;

  readonly manualSize?: boolean = false;

  readonly maOwnerCompanyBranchID?: number = 0;

  readonly maOwnerCompanyBranchShortName?: string = '';

  readonly mappings?: GenericMap[] = [];

  readonly marketplaceBuyerEventLaunchAt?: moment.Moment = undefined;

  readonly marketplaceBuyerIsPublic : boolean = false;

  readonly marketplaceBuyerIsPublish : boolean = false;

  readonly marketplaceBuyerReserveIsDisabled?: boolean = false;

  readonly marketplaceIsPublic?: boolean = true;

  readonly marketplaceIsPublish?: boolean = false;

  readonly maxPrice?: number = 0;

  readonly maxSize?: number = 0;

  readonly minPrice?: number = 0;

  readonly minSize?: number = 0;

  readonly mpAllowESignFormAtReserve?: boolean = false;

  readonly name: string = '';

  readonly newBookingEmailNotifications?: string[] = [];

  readonly onlinePaymentApproval?: boolean = true;

  readonly options?: GenericMap[] = [];

  readonly overview?: string = '';

  readonly overviewSubTitle?: string = '';

  readonly overviewTitle?: string = '';

  readonly ownershipType: OwnershipType = OwnershipType.LEASEHOLD;

  readonly paymentFieldsRequired?: boolean = false;

  readonly bookingFeeDeductiblePaymentPlan?: boolean = false;

  readonly paymentFlow?: boolean = true;

  readonly paymentPlanFlow?: boolean = false;

  readonly paymentTypeOptions?: string[] = ['bank_transfer', 'cheque', 'cash', 'credit_card', 'online_payment', 'others'];

  readonly paymsBpCollectionID?: string = '';

  readonly paymsBpCollectionTitle?: string = '';

  readonly paymsBpSignatureKey?: string = '';

  readonly paymsBpSecretKey?: string = '';

  readonly phone: string = '';

  readonly phonePrefix?: string = '60';

  readonly placeID?: string = '';

  readonly postcode: string = '';

  readonly printClause?: string = '';

  readonly printForms?: string[] = [];

  readonly printLoanReminderType?: string = '';

  readonly printPDF?: boolean = false;

  readonly printSPAType?: string = '';

  readonly printTerminationType?: string = '';

  readonly printType?: string = '';

  readonly projectPackagePricings?: ProjectPackagePricing[];

  readonly projectBankAccounts?: ProjectBankAccount[] = [];

  readonly projectBankAccount?: ProjectBankAccount[] = [];

  readonly projectFormAccessibilities?: ProjectFormAccessibility[] = [];

  readonly projectPictures?: ProjectPicture[] = [];

  readonly propertyCategory?: PropertyCategory = {} as PropertyCategory;

  readonly projectBookingCustomFields?: ProjectBookingCustomFieldMutationModel[];

  readonly propertyCategoryID: PropertyCategoryID = PropertyCategoryID.APARTMENT;

  readonly region?: string = '';

  readonly salesChartHidePrice?: boolean = false;

  readonly salesChartHideSize?: boolean = false;

  readonly salesChartRefreshSeconds?: number = 120;

  readonly salesChartSizePrice?: boolean = false;

  readonly salesChartTemplate?: string = '';

  readonly salesChartType?: string = '';

  readonly salesChartEnabled?: boolean = false;

  readonly siteplanAppEnabled?: boolean = false;

  readonly sendCustomerSuccessBookingEmail?: boolean = false;

  readonly sendCustomerCancelledBookingEmail?: boolean = false;

  readonly shortName: string = '';

  readonly showPurchaserExtraFields?: boolean = false;

  readonly siteplanID?: string = '';

  readonly siteplan?: ProjectSiteplan = {} as ProjectSiteplan;

  readonly siteplanSettings?: SiteplanSettings = {};

  readonly sizeSuffix: AreaUnits = AreaUnits.SQUARE_FEET;

  readonly soldStatusBookingEmailNotifications?: string[] = [];

  readonly sourceOfFinanceOptions?: string[] = ['referred_banker', 'own_banker', 'cash', 'government_loan', 'staff_loan', 'rent_to_own'];

  readonly spaReminderLetter?: string = '';

  readonly specifications?: GenericMap[] = [];

  readonly state?: string = '';

  readonly statusID: ProjectStatus = ProjectStatus.DRAFT;

  readonly summary?: ProjectSummary = {} as ProjectSummary;

  readonly terminationLetter?: string = '';

  readonly township?: string = '';

  constructor(project?: Project) {
    Object.assign(this, project);
  }
}
